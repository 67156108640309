import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment membershipAgreement on MembershipAgreement {
    id
    createdAt
    acceptedAt
    state
    managementFeesPercent
    extraManagementFeesPercent
    managementFeesPercentTotal
    paymentOption
    managementFeesCapped
    managementFeesCap
    file {
      ...blob
    }
  }
  ${blob}
`;
