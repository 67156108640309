import { useQuery } from "@apollo/client";
import { Paper, Typography, Box } from "@mui/material";

import GET_SAILOR_CONTRACTS, {
  GetSailorContractsData,
} from "apis/queries/getSailorContracts";
import GET_SAILOR_DOCUMENTS, {
  GetSailorDocumentsData,
} from "apis/queries/getSailorDocuments";
import LoadingOrError from "components/LoadingOrError";
import OnboardingStep from "components/OnboardingStep";
import StandardPaper from "components/StandardPaper";
import { REQUIRED_SAILOR_INFOS, REQUIRED_SAILOR_DOCS } from "constants/sailors";
import { Sailor } from "types";

interface SailorOnboardingStepsProps {
  sailor: Sailor;
}

const SailorOnboardingSteps = ({ sailor }: SailorOnboardingStepsProps) => {
  const {
    loading: documentsLoading,
    error: documentsError,
    data: documentsData,
  } = useQuery<GetSailorDocumentsData>(GET_SAILOR_DOCUMENTS, {
    variables: { sailorID: sailor.id },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const {
    loading: contractsLoading,
    error: contractsError,
    data: contractsData,
  } = useQuery<GetSailorContractsData>(GET_SAILOR_CONTRACTS, {
    variables: { sailorID: sailor.id, state: "accepted" },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  if (documentsError || contractsError || !documentsData || !contractsData)
    return (
      <StandardPaper>
        <LoadingOrError loading={documentsLoading || contractsLoading} />
      </StandardPaper>
    );

  const {
    node: { documents },
  } = documentsData;
  const {
    node: { contracts },
  } = contractsData;

  const nbRequiredDocuments = documents.reduce(
    (acc, doc) =>
      REQUIRED_SAILOR_DOCS.includes(doc.documentType) ? acc + 1 : acc,
    0
  );
  const nbRequiredInfos = REQUIRED_SAILOR_INFOS.reduce(
    (acc, info) => (sailor[info as keyof Sailor] ? acc + 1 : acc),
    0
  );
  const hasWorkContract = contracts.find(
    (c) => c.__typename === "WorkContract"
  );
  const hasServiceContract = contracts.find(
    (c) => c.__typename === "ServiceContract"
  );
  const hasMembershipAgreement = contracts.find(
    (c) => c.__typename === "MembershipAgreement"
  );

  const totalSteps =
    (nbRequiredDocuments === REQUIRED_SAILOR_DOCS.length ? 1 : 0) +
    (nbRequiredInfos === REQUIRED_SAILOR_INFOS.length ? 1 : 0) +
    (hasMembershipAgreement ? 1 : 0) +
    (hasServiceContract ? 1 : 0) +
    (hasWorkContract ? 1 : 0);

  return (
    <Paper sx={{ borderRadius: 2 }}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="subtitle2">
          Embarquement{" "}
          <span role="img" aria-label="champaign emoji">
            🍾
          </span>
          <Typography
            component="span"
            sx={{ color: "grey.600", fontWeight: "bold" }}
          >{` (${totalSteps}/5)`}</Typography>
        </Typography>
      </Box>
      <OnboardingStep
        value={nbRequiredDocuments}
        total={REQUIRED_SAILOR_DOCS.length}
        title="Liste des documents obligatoires à transmettre"
        subtitle="Pièce d'identité, justificatif de domicile, Attestation de sécurité sociale, CV."
        url="/profile?tab=2"
      />
      <OnboardingStep
        value={nbRequiredInfos}
        total={REQUIRED_SAILOR_INFOS.length}
        title="Compléter vos informations"
        subtitle="Plus que quelques informations à renseigner et vous embarquez !"
        url="/profile"
      />
      <OnboardingStep
        value={hasMembershipAgreement ? 1 : 0}
        total={1}
        title="Signer votre convention d'adhésion"
        subtitle="On vous détaille ici les conditions d'utilisations du portage, c'est obligatoire !"
      />
      <OnboardingStep
        value={hasServiceContract ? 1 : 0}
        total={1}
        title="Signer votre contrat de prestation"
        subtitle="Ce contrat régit les contours de votre mission (lieu d'intervention, conditions financières...)"
      />
      <OnboardingStep
        value={hasWorkContract ? 1 : 0}
        total={1}
        title="Signer votre contrat de travail"
        subtitle="C'est le contrat qui vous lie avec nous et qui vous permet de bénéficier de la protection sociale d'un salarié."
      />
    </Paper>
  );
};

export default SailorOnboardingSteps;
