import gql from "graphql-tag";

export default gql`
  fragment mission on Mission {
    id
    name
    description
    rate
    rateType
    startsOn
    endsOn
    location
    signatoryFirstName
    signatoryLastName
    signatoryEmail
    expenseSignatoryFirstName
    expenseSignatoryLastName
    expenseSignatoryEmail
    contractCcFirstName
    contractCcLastName
    contractCcEmail
    dailyExpensePackage
    managementFeesPercent
    paymentPeriod
    endOfMonthPayment
    paymentOption
    extraManagementFeesPercent
    managementFeesCapped
    managementFeesCap
    invoicesNote
    customMileageRate
    client {
      id
      name
      managementFeesCap
      mandatoryInvoicesNote
    }
  }
`;
