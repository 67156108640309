import { Typography } from "@mui/material";
import { FileDownloadOutline } from "mdi-material-ui";

import createExport from "apis/exports/create";
import TransactionsList from "components/dashboard/TransactionsList";
import DownloadExportButton from "components/DownloadExportButton";

interface TransactionsHistoryProps {
  sailorID: string;
}

const TransactionsHistory = ({ sailorID }: TransactionsHistoryProps) => (
  <TransactionsList sailorID={sailorID}>
    <Typography variant="labelLarge">Historique des transactions</Typography>
    <DownloadExportButton
      text="Exporter les transactions"
      size="small"
      Icon={FileDownloadOutline}
      downloadRequest={async () => createExport("SailorDataExport")}
    />
  </TransactionsList>
);

export default TransactionsHistory;
