import gql from "graphql-tag";

import { Stats } from "types";

export type MonthlyStats = Pick<
  Stats,
  | "paymentsWithoutActivityReportsAndRefunds"
  | "managementFeesTotal"
  | "settledInvoicedActivityReportsTotal"
  | "cvae"
  | "globalSalaryCost"
  | "otherPayments"
  | "lunchVouchersPayments"
  | "employeeSavingsPayments"
  | "employeeCommitteePayments"
  | "invoicedActivityReportsTotal"
>;

export type StatsDataByMonth = {
  node: {
    id: string;
    statsByMonth: MonthlyStats[];
  };
};

export default gql`
  query sailorStatsByMonth($id: ID!, $month: ISO8601Date!) {
    node(id: $id) {
      ... on Sailor {
        id
        statsByMonth(month: $month) {
          invoicedActivityReportsTotal
          managementFeesTotal
          cvae
          settledInvoicedActivityReportsTotal
          paymentsWithoutActivityReportsAndRefunds
          employeeSavingsPayments
          lunchVouchersPayments
          employeeCommitteePayments
          otherPayments
          globalSalaryCost
        }
      }
    }
  }
`;
