import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Typography,
  styled,
} from "@mui/material";
import { formatDistance } from "date-fns";
import frLocale from "date-fns/locale/fr";

import BadgeIcon from "components/BadgeIcon";
import Label from "components/Label";
import { TYPES_CATEGORIES } from "constants/notifications";
import { Notification } from "types";
import { sanitizeToString } from "utils/sanitizeHTML";
import { fullName } from "utils/users";

const UnreadBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "&.MuiBadge-root": {
    marginRight: 2,
    marginLeft: "auto",
  },
  "& .MuiBadge-badge": {
    top: -13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const NotificationCard = ({
  notification,
  isClientUserable = false,
}: {
  notification: Notification;
  isClientUserable: boolean;
}) => {
  const { type, createdAt, message, sailor } = notification;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 2,
      }}
    >
      {sailor && !isClientUserable ? (
        <Avatar
          alt={sailor.lastName}
          src={sailor.profilePic}
          style={{ width: 44, height: 44 }}
        />
      ) : (
        <BadgeIcon type={TYPES_CATEGORIES[type]} disableGradient />
      )}
      <Box sx={{ mx: 1.5 }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {sailor && !isClientUserable && (
            <Typography sx={{ fontWeight: 700 }}>
              {`${fullName(sailor)}\u00A0`}
            </Typography>
          )}
          <Typography
            dangerouslySetInnerHTML={{
              __html: sanitizeToString(message, { ALLOWED_TAGS: ["b"] }),
            }}
          />
        </Box>
        <Label
          sx={({ breakpoints }) => ({
            display: "block",
            color: "info.main",
            "&::first-letter": { textTransform: "uppercase" },
            [breakpoints.down("sm")]: { fontSize: "0.75rem" },
          })}
        >
          {formatDistance(new Date(createdAt), new Date(), {
            addSuffix: true,
            locale: frLocale,
          })}
        </Label>
      </Box>
      {!notification.readAt && <UnreadBadge color="primary" badgeContent=" " />}
    </Box>
  );
};

export default NotificationCard;
