import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import {
  DialogContent,
  DialogTitle,
  Rating,
  IconButton,
  Box,
} from "@mui/material";

interface NpsSurveyProps {
  note: number;
  handleVote: (newNote: number) => void;
  handleClose: () => void;
}

const NpsSurvey = ({ note, handleClose, handleVote }: NpsSurveyProps) => (
  <>
    <DialogTitle
      sx={{ width: "70%", mx: "auto" }}
      variant="subtitle2"
      textAlign="center"
    >
      Recommanderiez-vous Embarq à vos amis et collègues ?
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box
        sx={{
          width: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Rating
          size="large"
          value={note}
          onChange={(event, newNote: number | null) => {
            handleVote(newNote!);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </Box>
    </DialogContent>
  </>
);

export default NpsSurvey;
