import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment workContract on WorkContract {
    id
    createdAt
    acceptedAt
    state
    startsOn
    endsOn
    legalStatus
    file {
      ...blob
    }
  }
  ${blob}
`;
