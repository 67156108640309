import { ReactNode, forwardRef, ForwardedRef } from "react";
import { AddOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { Property } from "csstype";

import CustomIconButton from "components/basics/CustomIconButton";

interface AddButtonProps extends ButtonProps {
  children?: ReactNode;
  textTransform?: Property.TextTransform;
  innerRef?: ForwardedRef<HTMLButtonElement>;
}

const AddButton = ({
  children,
  textTransform,
  size,
  innerRef,
  ...props
}: AddButtonProps) =>
  children ? (
    <Button
      style={{ textTransform }}
      variant="contained"
      color="primary"
      startIcon={<AddOutlined />}
      size={size}
      ref={innerRef}
      {...props}
    >
      {children}
    </Button>
  ) : (
    <CustomIconButton
      variant="contained"
      color="primary"
      icon={AddOutlined}
      size="large"
      ref={innerRef}
      {...props}
    />
  );

export default forwardRef<HTMLButtonElement, AddButtonProps>((props, ref) => (
  <AddButton innerRef={ref} {...props} />
));
