import gql from "graphql-tag";

import attachment from "apis/fragments/attachment";
import blob from "apis/fragments/blob";

export default gql`
  fragment document on Document {
    id
    name
    documentType
    state
    pages {
      ...attachment
    }
    file {
      ...blob
    }
  }
  ${attachment}
  ${blob}
`;
