import { Box, Typography } from "@mui/material";

import BadgeIcon from "components/BadgeIcon";
import { formatCurrencyWithSign } from "utils/formatNumber";
import {
  amountForTransaction,
  labelForTransaction,
  TransactionItemProps,
  categoryForTransaction,
  formatTransactionDate,
} from "utils/transactions";

const TransactionItem = ({ transaction, future }: TransactionItemProps) => {
  const category = categoryForTransaction(transaction);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        opacity: future ? 0.6 : 1,
        alignItems: { xs: "center", sm: "start" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <BadgeIcon type={category} squared />
        <div>
          <Typography variant="body">
            {labelForTransaction(transaction)}
          </Typography>
          <Typography
            variant="labelSmall"
            sx={{
              color: "info.main",
            }}
          >
            {formatTransactionDate(transaction)}
          </Typography>
        </div>
      </Box>
      <Typography variant="label">
        {formatCurrencyWithSign(amountForTransaction(transaction))}
      </Typography>
    </Box>
  );
};

export default TransactionItem;
