import { Box, Typography, Tooltip } from "@mui/material";

import { MonthlyStats } from "apis/queries/sailorStatsByMonth";
import {
  ACTIVITIES_SUMS,
  PAYMENTS_SUM,
  BalanceCategory,
} from "constants/transactionsCategories";
import { formatCurrency } from "utils/formatNumber";

type SailorMonthlyBalanceDetailsProps = {
  statsByMonth: MonthlyStats[];
  activeMonth: number;
};

interface SailorMontlyCategoryRecapProps
  extends SailorMonthlyBalanceDetailsProps {
  category: BalanceCategory;
}

const SailorMontlyCategoryRecap = ({
  category,
  activeMonth,
  statsByMonth,
}: SailorMontlyCategoryRecapProps) => (
  <Tooltip
    title={
      category.dataType === "globalSalaryCost"
        ? "Le salaire inclut le montant de la réserve."
        : ""
    }
    placement="bottom"
    enterTouchDelay={0}
  >
    <Box
      sx={{
        display: "flex",
        borderLeft: category.main ? 0 : 2,
        borderColor: "grey.300",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {category.main && (
          <Box
            sx={{
              alignSelf: "center",
              width: 24,
              height: 24,
              borderRadius: 2,
              ml: -1.3,
              border: 4,
              borderColor: "background.paper",
              background: category.color,
            }}
          />
        )}
        <Typography
          variant="bodySmall"
          sx={{
            justifySelf: "flex-start",
            color: category.main ? "grey.900" : "grey.700",
            pl: category.main ? 1 : 3,
          }}
        >
          {category.label}
        </Typography>
      </Box>
      <Typography
        variant={category.main ? "label" : "labelSmall"}
        sx={{
          color: category.main ? "text.primary" : "grey.700",
        }}
      >
        {formatCurrency(statsByMonth[activeMonth][category.dataType])}
      </Typography>
    </Box>
  </Tooltip>
);

const SailorMonthlyBalanceDetails = ({
  statsByMonth,
  activeMonth,
}: SailorMonthlyBalanceDetailsProps) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      mt: 2,
      flexWrap: "wrap",
    }}
  >
    {[ACTIVITIES_SUMS, PAYMENTS_SUM].map((side, index) => (
      <Box
        key={index === 0 ? "activities" : "payments"}
        sx={{
          flex: 1,
          px: 2,
          minWidth: 220,
          mb: 2,
        }}
      >
        {side.map((cat: BalanceCategory) => (
          <SailorMontlyCategoryRecap
            key={`${activeMonth}${cat.dataType}`}
            category={cat}
            statsByMonth={statsByMonth}
            activeMonth={activeMonth}
          />
        ))}
      </Box>
    ))}
  </Box>
);

export default SailorMonthlyBalanceDetails;
