import { useState } from "react";
import { Collapse, Link, Box } from "@mui/material";

import StyledAlert from "components/basics/StyledAlert";

const OnboardingCallAlert = ({ agendaUrl }: { agendaUrl: string }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <Collapse in={open}>
      <StyledAlert
        severity="info"
        title="Mise en place de votre contrat de travail"
        onClose={handleClose}
      >
        <Link
          sx={{ color: "info.dark", fontWeight: "medium" }}
          href={agendaUrl}
          underline="hover"
        >
          Planifiez votre rendez-vous téléphonique
        </Link>{" "}
        <Box
          sx={{
            display: "inline",
            color: "text.primary",
          }}
        >
          sur l’agenda de votre Account Manager
        </Box>
      </StyledAlert>
    </Collapse>
  );
};

export default OnboardingCallAlert;
