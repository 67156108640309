import gql from "graphql-tag";

import document from "apis/fragments/document";
import { Document } from "types";

export type GetSailorDocumentsData = {
  node: { documents: Document[] };
};

export default gql`
  query getSailorDocuments($sailorID: ID!, $documentType: String) {
    node(id: $sailorID) {
      ... on Sailor {
        id
        documents(documentType: $documentType) {
          ...document
        }
      }
    }
  }
  ${document}
`;
