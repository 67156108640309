import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { ChevronRightOutlined } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import format from "date-fns/format";
import { fr } from "date-fns/locale";

import DECLARE_INACTIVITY from "apis/mutations/declareMissionInactivity";
import { Mission, Client } from "types";

export type MissionToDeclare = {
  missionID: Mission["id"];
  clientName: Client["name"];
  month: Date;
  activityReport: boolean;
  expenseReport: boolean;
};

interface MissionDeclarationProps {
  mission: MissionToDeclare;
}

const formatMonth = (month: Date) => format(month, "LLLL Y", { locale: fr });

const DATA = {
  activity_report: {
    text: ({
      clientName,
      month,
    }: Pick<MissionToDeclare, "clientName" | "month">) =>
      `Avez-vous travaillé pour ${clientName} en ${formatMonth(month)} ?`,
    newURL: "/activities?action=new",
    declareActivityMsg: "Oui, déclarer mon activité",
    declareInactivityMsg: "Non, je n'ai pas eu d'activité",
  },
  expense: {
    text: ({
      clientName,
      month,
    }: Pick<MissionToDeclare, "clientName" | "month">) =>
      `Avez-vous des notes de frais déductibles pour ${clientName} à déclarer en ${formatMonth(
        month
      )} ?`,
    newURL: "/expenses?action=new",
    declareActivityMsg: "Oui, ajouter une note de frais",
    declareInactivityMsg: "Non, je n'ai pas de note de frais",
  },
};

const MissionDeclaration = ({
  mission: { missionID, clientName, month, activityReport },
}: MissionDeclarationProps) => {
  const router = useRouter();
  const [declareInactivity] = useMutation(DECLARE_INACTIVITY);
  const inactivityType = activityReport ? "activity_report" : "expense";
  const data = DATA[inactivityType];

  return (
    <>
      <Typography
        sx={{
          textAlign: "center",
          mb: 1,
        }}
      >
        {data.text({ clientName, month })}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        size="small"
        endIcon={<ChevronRightOutlined />}
        sx={{ mb: 1 }}
        onClick={() => router.push(data.newURL)}
      >
        {data.declareActivityMsg}
      </Button>
      <Button
        fullWidth
        size="small"
        onClick={() =>
          declareInactivity({ variables: { missionID, inactivityType } })
        }
      >
        {data.declareInactivityMsg}
      </Button>
    </>
  );
};

export default MissionDeclaration;
