import { Dispatch, SetStateAction, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartEvent,
  ActiveElement,
  Legend,
  ChartOptions,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart } from "react-chartjs-2";

import { MonthlyStats } from "apis/queries/sailorStatsByMonth";
import theme from "utils/theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

interface ChartProps {
  rawData: MonthlyStats[];
  activeMonth: number;
  labels: string[];
  setActiveMonth: Dispatch<SetStateAction<number>>;
}

const SailorHistoryChart = ({
  rawData,
  activeMonth,
  labels,
  setActiveMonth,
}: ChartProps) => {
  const chartRef = useRef<ChartJS | null>(null);

  const options = {
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      annotation: {
        annotations: {
          box: {
            drawTime: "beforeDraw",
            type: "box",
            xMin: -0.5 + activeMonth,
            xMax: 0.5 + activeMonth,
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderWidth: 0,
          },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    onClick: (_: ChartEvent, items: ActiveElement[]) => {
      setActiveMonth(items[0].index);
    },
    scales: {
      x: {
        stacked: true,
        drawBorder: false,
        grid: {
          display: false,
        },
        ticks: {
          color: (t: any) =>
            t.index === activeMonth
              ? theme.palette.text.primary
              : theme.palette.grey[500],
        },
      },
      y: {
        stacked: false,
        grid: {
          display: true,
          borderDash: [2, 2],
        },
      },
    },
  };

  const getDatasetFromMonth = (dataType: keyof MonthlyStats) =>
    rawData.map((m: MonthlyStats) => m[dataType]);

  const updateChartData = () => {
    const salaryDataset = getDatasetFromMonth("globalSalaryCost");
    const invoicedDataset = getDatasetFromMonth("invoicedActivityReportsTotal");
    const settledDataset = getDatasetFromMonth(
      "settledInvoicedActivityReportsTotal"
    );
    const paymentsDataset = getDatasetFromMonth(
      "paymentsWithoutActivityReportsAndRefunds"
    );

    return {
      salaryDataset,
      invoicedDataset,
      settledDataset,
      paymentsDataset,
    };
  };

  const { salaryDataset, invoicedDataset, settledDataset, paymentsDataset } =
    updateChartData();

  const chartData = {
    labels,
    datasets: [
      {
        labe: "Réglé",
        data: settledDataset,
        backgroundColor: theme.palette.graph.darkGreen,
        stack: "Stack 1",
      },
      {
        label: "Facturé",
        data: invoicedDataset,
        backgroundColor: theme.palette.graph.lightGreen,
        stack: "Stack 1",
      },
      {
        label: "Salaire",
        data: salaryDataset,
        backgroundColor: theme.palette.graph.darkBlue,
        stack: "Stack 0",
      },
      {
        label: "Versement",
        data: paymentsDataset,
        backgroundColor: theme.palette.graph.lightBlue,
        stack: "Stack 0",
      },
    ],
  };

  return (
    <Chart
      type="bar"
      ref={chartRef}
      options={options as ChartOptions}
      data={chartData}
      style={{ cursor: "pointer" }}
    />
  );
};

export default SailorHistoryChart;
