import React, { useEffect, useState, ReactNode } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Drawer, DrawerProps, IconButton } from "@mui/material";

const getElementHeight = (id: string) =>
  document?.getElementById(id)?.offsetHeight || 0;

export interface RecordDrawerProps extends DrawerProps {
  onClose?: () => void;
  width?: number;
  headerComponent?: ReactNode;
  submitButton?: ReactNode | string;
  onSubmit?: () => void;
}

const RecordDrawer = ({
  onClose,
  width = 540,
  children,
  headerComponent,
  submitButton,
  onSubmit,
  ...props
}: RecordDrawerProps) => {
  const [elementsHeights, setElementsHeights] = useState({
    drawer: 0,
    header: 0,
    bottom: 0,
  });
  const { open } = props;

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Escape") onClose?.();
  };

  const CloseIconButton = () => (
    <IconButton
      onClick={onClose}
      aria-label="close"
      size="large"
      sx={{ p: 0.25 }}
    >
      <CloseOutlined />
    </IconButton>
  );

  useEffect(() => {
    if (open) window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [open]);

  useEffect(() => {
    setElementsHeights({
      drawer: getElementHeight("drawer"),
      header: getElementHeight("header"),
      bottom: getElementHeight("bottom"),
    });
  }, [headerComponent, submitButton]);

  return (
    <Drawer
      anchor="right"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        id: "drawer",
        sx: { width: { xs: "100%", sm: width } },
      }}
      {...props}
    >
      <Box
        id="header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          borderBottom: "1px solid #E0E0E0",
          p: { xs: 1, sm: 3 },
        }}
      >
        {headerComponent}
        {onClose && <CloseIconButton />}
      </Box>
      <Box
        sx={{
          maxHeight:
            elementsHeights.drawer -
            elementsHeights.header -
            elementsHeights.bottom,

          minHeight: `calc(100% - ${elementsHeights.header}px - ${elementsHeights.bottom}px)`,
          p: { xs: 1, sm: 3 },
          overflowY: "scroll",
        }}
      >
        {children}
      </Box>
      {submitButton && (
        <Box
          id="bottom"
          sx={{
            borderTop: "1px solid #E0E0E0",
            display: "flex",
            justifyContent: "flex-end",
            columnGap: 1,
            p: [1, 2],
          }}
        >
          {typeof submitButton === "string" ? (
            <Button variant="contained" onClick={onSubmit}>
              {submitButton}
            </Button>
          ) : (
            submitButton
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default RecordDrawer;
