import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";

import GET_SAILOR_BALANCES, {
  SailorBalancesData,
} from "apis/queries/sailorBalances";
import TransactionsList from "components/dashboard/TransactionsList";
import LoadingOrError from "components/LoadingOrError";
import { formatCurrency } from "utils/formatNumber";

interface TransactionsForecastProps {
  sailorID: string;
}

const TransactionsForecast = ({ sailorID }: TransactionsForecastProps) => {
  const { data, loading } = useQuery<SailorBalancesData>(GET_SAILOR_BALANCES, {
    fetchPolicy: "cache-only",
    variables: { id: sailorID },
  });

  if (!data || loading) {
    return <LoadingOrError loading={loading} minHeight={130} />;
  }

  const {
    node: {
      stats: { forecastSettledAmount },
    },
  } = data;

  return (
    <TransactionsList sailorID={sailorID} forecast>
      <Typography variant="labelLarge">Transactions à venir</Typography>
      <Typography variant="label">
        Total : {formatCurrency(forecastSettledAmount)}
      </Typography>
    </TransactionsList>
  );
};

export default TransactionsForecast;
