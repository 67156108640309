import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";

import { Event } from "constants/events";
import { formatDate } from "utils/dates";

const EventDialog = ({ event }: { event: Event }) => {
  const [open, setOpen] = useState<boolean>(true);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("eventDialogSeen", new Date().toDateString());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogTitle sx={{ p: 0 }}>{event.title}</DialogTitle>
        <DialogContentText>{event.subTitle}</DialogContentText>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
      <Divider />
      <DialogContent>
        <DialogContentText sx={{ color: "text.primary" }}>
          🗓️ Date : {formatDate(event.date)}
        </DialogContentText>
        <DialogContentText sx={{ color: "text.primary" }}>
          📍Lieu : {event.location}
        </DialogContentText>
        <DialogContentText sx={{ color: "text.primary" }}>
          👥 Places Limitées : {event.tickets} places seulement !
        </DialogContentText>
        <DialogContentText sx={{ mt: 3, color: "text.primary" }}>
          {event.description}
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "center", mt: 1 }}>
          <Button variant="contained" href={event.url} target="_blank">
            Je réserve ma place
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EventDialog;
