import { useRouter } from "next/router";
import { CheckOutlined, ChevronRightOutlined } from "@mui/icons-material";
import {
  Typography,
  Divider,
  Box,
  CircularProgress,
  styled,
} from "@mui/material";

const CheckedCircle = styled("div")(({ theme: { palette } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: palette.success.main,
  "& .MuiSvgIcon-root": { color: palette.common.white },
}));

interface OnboardingStepProps {
  value: number;
  total: number;
  title: string;
  subtitle?: string;
  url?: string;
}

const OnboardingStep = ({
  value,
  total,
  title,
  subtitle,
  url,
}: OnboardingStepProps) => {
  const router = useRouter();

  const progression = (value / total) * 100;
  const isClickable = url && progression < 100;

  const Progress = () => (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        thickness={2}
        value={100}
        sx={{ color: "grey.300" }}
      />
      <CircularProgress
        variant="determinate"
        thickness={2}
        value={progression}
        sx={{ position: "absolute", color: "success.main" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "grey.600",
          }}
        >{`${value}/${total}`}</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Divider />
      <Box
        onClick={isClickable && url ? () => router.push(url) : undefined}
        style={{ cursor: isClickable ? "pointer" : "initial" }}
        sx={{
          py: [1, 2],
          pl: [1, 3],
          pr: [1, 2],
          display: "flex",
          alignItems: "center",
        }}
      >
        {value < total ? (
          <Progress />
        ) : (
          <CheckedCircle>
            <CheckOutlined />
          </CheckedCircle>
        )}

        <Box
          sx={{
            ml: 2,
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Box>
        {isClickable && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChevronRightOutlined color="primary" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default OnboardingStep;
