import { useState } from "react";
import { Paper } from "@mui/material";

import SailorBalanceSummary from "components/dashboard/SailorBalanceSummary";
import TransactionsForecast from "components/dashboard/TransactionsForecast";
import TransactionsHistory from "components/dashboard/TransactionsHistory";

interface SailorBalanceAndTransactionsProps {
  sailorID: string;
}

const SailorBalanceAndTransactions = ({
  sailorID,
}: SailorBalanceAndTransactionsProps) => {
  const [forecast, setForecast] = useState(false);

  return (
    <Paper sx={{ borderRadius: 2 }} id="stats">
      <SailorBalanceSummary
        sailorID={sailorID}
        forecast={forecast}
        setForecast={setForecast}
      />
      {forecast ? (
        <TransactionsForecast sailorID={sailorID} />
      ) : (
        <TransactionsHistory sailorID={sailorID} />
      )}
    </Paper>
  );
};

export default SailorBalanceAndTransactions;
