import handleResponse from "./handleResponse";

const handleFileDataResponse = (response: Response) => {
  if (!response.ok) return handleResponse(response);

  const filename = response.headers
    ?.get("Content-Disposition")
    ?.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/)?.[1];

  return Promise.all([response.blob(), filename]);
};

export default handleFileDataResponse;
