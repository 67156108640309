import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment attachment on Attachment {
    id
    blob {
      ...blob
    }
  }
  ${blob}
`;
