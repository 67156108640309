import fetch from "isomorphic-fetch";
import cookie from "js-cookie";

import handleFileDataResponse from "apis/handleFileDataResponse";
import handleNetworkError from "apis/handleNetworkError";
import handleResponse from "apis/handleResponse";

type ExportType = "SailorDataExport";

// Creates an export and immediately download the file
const createExport = async (exportType: ExportType) => {
  const accessToken = cookie.get("access_token");

  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/exports`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type: exportType }),
  })
    .catch(handleNetworkError)
    .then(handleResponse)
    .then((exportResponse) => fetch(exportResponse.file_url, { method: "GET" }))
    .catch(handleNetworkError)
    .then(handleFileDataResponse);
};

export default createExport;
