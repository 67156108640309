import { ReactNode, useState } from "react";
import cookie from "js-cookie";

import NotificationsDrawer from "components/basics/Notifications/drawer";
import Toolbar from "components/dashboard/DashboardToolbar";
import Title from "components/Title";
import defaultMetaTitle from "constants/pageMetaData";

interface DashboardLayoutProps {
  title?: string;
  children: ReactNode;
  toolbarIcons?: ReactNode;
}

const DashboardLayout = ({
  title,
  children,
  toolbarIcons,
}: DashboardLayoutProps) => {
  const [open, setOpen] = useState(false);
  const userID = cookie.get("user_id") as string;

  const label = title || defaultMetaTitle.dashboard;

  return (
    <>
      <Title label={label} />
      <Toolbar
        onDisplayNotifications={() => setOpen(true)}
        title={label}
        actions={toolbarIcons}
      />
      {children}
      <NotificationsDrawer
        open={open}
        onClose={() => setOpen(false)}
        userID={userID}
      />
    </>
  );
};

export default DashboardLayout;
