import { useQuery } from "@apollo/client";
import { ChevronRightOutlined, CloseOutlined } from "@mui/icons-material";
import { Typography, Tooltip, Box } from "@mui/material";

import GET_SAILOR_BALANCES, {
  SailorBalancesData,
} from "apis/queries/sailorBalances";
import LoadingOrError from "components/LoadingOrError";
import { formatCurrency, formatCurrencyWithSign } from "utils/formatNumber";

interface SailorBalanceSummaryProps {
  sailorID: string;
  forecast: boolean;
  setForecast: (forecast: boolean) => void;
}

const SailorBalanceSummary = ({
  sailorID,
  forecast,
  setForecast,
}: SailorBalanceSummaryProps) => {
  const { loading, error, data } = useQuery<SailorBalancesData>(
    GET_SAILOR_BALANCES,
    {
      fetchPolicy: "cache-and-network",
      variables: { id: sailorID },
    }
  );

  if (error || !data)
    return <LoadingOrError minHeight={130} loading={loading} />;

  const {
    node: {
      stats: { availableBalance, provisionBalance, forecastSettledAmount },
    },
  } = data;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        p: [2, 2, 3],
        gap: 2,
        borderBottom: "2px solid",
        borderColor: "grey.200",
      }}
    >
      <Tooltip
        title={
          forecast
            ? "Solde à venir = Solde disponible + Mouvements à venir (Facturation HT - Frais de gestion - CVAE)"
            : "Solde disponible = Facturation HT - Frais de gestion - CVAE"
        }
        placement="bottom-start"
        enterTouchDelay={0}
      >
        <Box>
          <Typography
            variant="label"
            sx={{
              color: "grey.600",
            }}
          >
            {forecast ? "Solde à venir" : "Solde disponible"}
          </Typography>
          <Typography variant="h1">
            {formatCurrency(
              forecast
                ? forecastSettledAmount + availableBalance
                : availableBalance
            )}
          </Typography>
          <Typography
            variant="overline"
            component="span"
            sx={{
              color: "grey.600",
            }}
          >
            Réserve :{" "}
          </Typography>
          <Typography variant="overline" component="span">
            {formatCurrency(provisionBalance)}
          </Typography>
        </Box>
      </Tooltip>
      {!forecast && (
        <Box
          onClick={() => setForecast(true)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: [1, 2],
            border: 1,
            borderColor: "grey.300",
            borderRadius: 2,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: [2, 3, 8],
              }}
            >
              <Typography variant="bodySmall">Mouvements à venir</Typography>
              <Typography variant="label">
                {formatCurrencyWithSign(forecastSettledAmount)}
              </Typography>
            </Box>
            <Tooltip
              title="Solde à venir = Solde disponible + Mouvements à venir (Facturation HT - Frais de gestion - CVAE)"
              placement="bottom-start"
              enterTouchDelay={0}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="bodySmall">Solde à venir</Typography>
                <Typography variant="label">
                  {formatCurrency(forecastSettledAmount + availableBalance)}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              alignSelf: "center",
              ml: [1, 2],
            }}
          >
            <ChevronRightOutlined color="primary" />
          </Box>
        </Box>
      )}
      {forecast && (
        <CloseOutlined
          color="primary"
          onClick={() => setForecast(false)}
          cursor="pointer"
        />
      )}
    </Box>
  );
};

export default SailorBalanceSummary;
