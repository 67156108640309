import { forwardRef, useContext, useState } from "react";
import {
  Button,
  IconButton,
  CircularProgress,
  ButtonProps,
  SvgIconTypeMap,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { DatabaseExport } from "mdi-material-ui";

import { SnackbarContext } from "./SnackbarContext";
import { errorMessage } from "utils/handleSubmitError";

interface DownloadExportButtonProps extends ButtonProps {
  downloadRequest: () => Promise<[Blob, string | undefined]>;
  text?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const DownloadExportButton = ({
  downloadRequest,
  text = "Exporter",
  Icon = DatabaseExport,
  size,
  ...props
}: DownloadExportButtonProps) => {
  const [downloading, setDownloading] = useState(false);
  const [snackbarContext, setSnackbarContext] = useContext(SnackbarContext);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = async () => {
    try {
      setDownloading(true);
      const [blob, filename] = await downloadRequest();
      saveAs(
        blob,
        filename || `export-${format(new Date(), "yyyy-MM-dd-HH:mm")}`
      );
    } catch (e) {
      if (e instanceof Error)
        setSnackbarContext({
          ...snackbarContext,
          open: true,
          severity: "error",
          message: errorMessage(e),
        });
    } finally {
      setDownloading(false);
    }
  };

  return isXSmall ? (
    <IconButton color="primary" size="large" {...props}>
      {downloading ? (
        <CircularProgress size={20} thickness={2} color="secondary" />
      ) : (
        <Icon />
      )}
    </IconButton>
  ) : (
    <Button
      startIcon={
        downloading ? (
          <CircularProgress size={20} thickness={2} color="secondary" />
        ) : (
          <Icon />
        )
      }
      variant="outlined"
      color="primary"
      size={size}
      onClick={handleClick}
      disabled={downloading}
      {...props}
    >
      {text}
    </Button>
  );
};

export default forwardRef<HTMLButtonElement, DownloadExportButtonProps>(
  (props, ref) => <DownloadExportButton ref={ref} {...props} />
);
