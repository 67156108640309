import { differenceInDays } from "date-fns";

import { SailorSurvey } from "types";

const isSailorSurveyable = (lastSailorSurvey: SailorSurvey) => {
  if (lastSailorSurvey.rating > 0) return false;
  if (lastSailorSurvey.wantToRespond === true) return true;

  return (
    differenceInDays(new Date(), new Date(lastSailorSurvey.updatedAt)) >= 30
  );
};

export default isSailorSurveyable;
