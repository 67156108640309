import { useQuery } from "@apollo/client";
import { Box, Paper, Grid2 as Grid, Typography, Divider } from "@mui/material";

import GET_SAILOR_CREW_MEMBERS, {
  SailorCrewMembersData,
} from "apis/queries/sailor/crewMembers";
import LoadingOrError from "components/LoadingOrError";
import ReferralCard from "components/ReferralCard";
import SailorContactCard from "components/SailorContactCard";
import ServiceCard from "components/ServiceCard";

interface ServicesAndContactPaperProps {
  sailorID: string;
  completedOnboardingCall?: boolean;
}

function ServicesAndContactPaper({
  sailorID,
  completedOnboardingCall,
}: ServicesAndContactPaperProps) {
  const { loading, error, data } = useQuery<SailorCrewMembersData>(
    GET_SAILOR_CREW_MEMBERS,
    {
      variables: { id: sailorID },
      fetchPolicy: "cache-and-network",
    }
  );

  if (loading || error || !data)
    return (
      <Paper sx={{ borderRadius: 2 }}>
        <LoadingOrError minHeight={350} loading={loading} />
      </Paper>
    );

  const accountManager = data.node?.accountManager;
  const businessDeveloper = data.node?.businessDeveloper;

  return (
    <Grid
      container
      spacing={[0, 2, 2, 3]}
      sx={{
        alignItems: "flex-start",
      }}
    >
      <Grid
        size={{
          xs: 12,
          sm: 6,
          lg: 12,
        }}
      >
        <Paper sx={{ pb: 1, borderRadius: 2 }}>
          <Divider
            sx={{
              opacity: "0.6",
            }}
          />
          <ServiceCard
            title="Vos services"
            description="Profitez d'avantages exclusifs Embarq tels que les tickets resto (Swile) ou notre CE (Leeto)"
            href="/profile?tab=4"
          />
          <Divider
            sx={{
              opacity: "0.6",
            }}
          />
          <ServiceCard
            id="aide"
            title="Centre d'aide"
            description="On vous explique comment la plateforme fonctionne de A à Z."
            href="/help"
            openNewTab
          />
          <Divider
            sx={{
              opacity: "0.6",
            }}
          />
          <ServiceCard
            title="Nos évènements"
            description="Ne ratez pas notre prochaine rencontre ou webinaire."
            href="https://www.embarq.fr/virtual-events"
            openNewTab
          />
        </Paper>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 6,
          lg: 12,
        }}
      >
        <ReferralCard />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 6,
          lg: 12,
        }}
      >
        {(accountManager || businessDeveloper) && (
          <Typography
            variant="labelLarge"
            sx={{
              my: 2,
            }}
          >
            Contacts
          </Typography>
        )}
        {accountManager && (
          <Box
            sx={{
              mb: 2,
            }}
          >
            <SailorContactCard
              title="Account Manager"
              crewMember={accountManager}
              hidePhoneNumber={!completedOnboardingCall}
            />
          </Box>
        )}
        {businessDeveloper && (
          <SailorContactCard
            title="Business Developer"
            crewMember={businessDeveloper}
            hidePhoneNumber={!completedOnboardingCall}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default ServicesAndContactPaper;
