import { Collapse, CollapseProps } from "@mui/material";

import StyledAlert from "components/basics/StyledAlert";

interface OffboardedAlertProps extends Pick<CollapseProps, "sx"> {
  display: boolean;
}

const OffboardedAlert = ({ display, sx }: OffboardedAlertProps) => (
  <Collapse in={display} sx={sx}>
    <StyledAlert
      severity="warning"
      title="Votre compte est en cours de débarquement ou débarqué, les actions liées
        à votre activité ne sont plus disponibles."
    />
  </Collapse>
);

export default OffboardedAlert;
