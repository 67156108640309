import { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Dialog } from "@mui/material";

import UPDATE_NPS, {
  UpdateSailorSurveyData,
} from "apis/mutations/updateSailorSurvey";
import NpsFollowUp from "components/NpsFollowUp";
import NpsSurvey from "components/NpsSurvey";
import { SnackbarContext } from "components/SnackbarContext";
import { SailorSurvey } from "types";
import { errorMessage } from "utils/handleSubmitError";

interface NpsDialogProps {
  sailorSurvey: SailorSurvey;
}

const NpsDialog = ({ sailorSurvey }: NpsDialogProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [snackbarContext, setSnackbarContext] = useContext(SnackbarContext);
  const [updateSailorSurvey] = useMutation<UpdateSailorSurveyData>(UPDATE_NPS);
  const [note, setNote] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [sentiment, setSentiment] = useState<"positive" | "negative">(
    "positive"
  );

  const handleSubmit = async () => {
    try {
      await updateSailorSurvey({
        variables: {
          id: sailorSurvey.id,
          rating: note || null,
          comment,
          wantToRespond: note !== 0,
        },
      });
    } catch (err) {
      setSnackbarContext({
        ...snackbarContext,
        open: true,
        message: errorMessage(err),
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleSubmit();
  };

  const handleAction = () => {
    setOpen(false);
    if (sentiment === "positive") {
      window.open(
        "http://search.google.com/local/writereview?placeid=ChIJV1UxLPlt5kcR-GYnPraZsYE",
        "_blank"
      );
    }
    handleSubmit();
  };

  const handleVote = (newNote: number) => {
    setSentiment(newNote > 3 ? "positive" : "negative");
    setNote(newNote);
  };

  return (
    <Dialog PaperProps={{ sx: { p: 4 } }} open={open} onClose={handleClose}>
      {note ? (
        <NpsFollowUp
          handleAction={handleAction}
          sentiment={sentiment}
          comment={comment}
          setComment={setComment}
        />
      ) : (
        <NpsSurvey
          note={note}
          handleClose={handleClose}
          handleVote={handleVote}
        />
      )}
    </Dialog>
  );
};

export default NpsDialog;
