import Image from "next/image";
import { useRouter } from "next/router";
import { Box, Typography, Button } from "@mui/material";

import theme from "utils/theme";

const ReferralCard = () => {
  const router = useRouter();

  const handleClick = () => router.push("/profile?tab=5");

  return (
    <Box
      sx={{
        p: 3,
        position: "relative",
        borderRadius: 2,
        overflow: "hidden",
        backgroundColor: theme.palette.referral.background,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            zIndex: 2,
          }}
        >
          Parrainez vos amis
        </Typography>
        <Typography
          sx={{
            zIndex: 2,
          }}
        >
          Une carte cadeau de 100€ est offerte au parrain et au filleul pour
          chaque parrainage validé.
        </Typography>
        <Button variant="contained" onClick={handleClick} color="primary">
          Inviter des amis
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 10,
          bottom: 10,
          zIndex: 1,
        }}
      >
        <Image
          alt="Amazon gift card"
          src="/images/darty-fnac-image.png"
          width={113}
          height={50}
        />
      </Box>
    </Box>
  );
};
export default ReferralCard;
