import gql from "graphql-tag";

import { Mission } from "types";

export type DeclareMissionInactivityData = {
  declareMissionInactivity: {
    mission: Pick<
      Mission,
      "id" | "monthsWithoutActivity" | "monthsWithoutExpense"
    >;
  };
};

export default gql`
  mutation declareMissionInactivity($missionID: ID!, $inactivityType: String!) {
    declareMissionInactivity(
      input: { missionId: $missionID, inactivityType: $inactivityType }
    ) {
      mission {
        id
        monthsWithoutActivity
        monthsWithoutExpense
      }
    }
  }
`;
