import { useState } from "react";
import { useRouter } from "next/router";
import { Box, Menu, MenuItem, useMediaQuery, Tooltip } from "@mui/material";

import AddButton from "components/AddButton";
import { Sailor } from "types";
import theme from "utils/theme";

type SailorShortcut = {
  label: string;
  path: string;
};

type SailorShortcutsProps = {
  sailorState?: Sailor["state"];
};

const SailorShortcuts = ({ sailorState }: SailorShortcutsProps) => {
  const router = useRouter();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSailorOff =
    sailorState === "offboarded" || sailorState === "offboarding";

  const handleClose = (path: string) => {
    router.push(path);
    setAnchorEl(null);
  };
  const SAILOR_SHORTCUTS: SailorShortcut[] = [
    {
      label: "Activité",
      path: "/activities?action=new",
    },
    {
      label: "Frais",
      path: "/expenses?action=new",
    },
  ];

  return (
    <Box
      id="shortcuts"
      sx={{
        display: "flex",
        columnGap: 2,
        mr: 2,
      }}
    >
      {isXSmall ? (
        <>
          <AddButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={isSailorOff}
          />
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{ borderShadow: "none" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={1}
          >
            {SAILOR_SHORTCUTS.map((item) => (
              <MenuItem key={item.label} onClick={() => handleClose(item.path)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        SAILOR_SHORTCUTS.map((item) => (
          <Tooltip
            title={isSailorOff ? "Cette action n'est plus disponible" : ""}
            key={item.label}
          >
            <div>
              <AddButton
                variant="outlined"
                onClick={() => router.push(item.path)}
                disabled={isSailorOff}
              >
                {item.label}
              </AddButton>
            </div>
          </Tooltip>
        ))
      )}
    </Box>
  );
};

export default SailorShortcuts;
