import { Box, Paper, Avatar, Typography } from "@mui/material";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { CrewMember } from "types";
import { fullName } from "utils/users";

interface SailorContactCardProps {
  title: string;
  hidePhoneNumber?: boolean;
  crewMember: CrewMember;
}

const SailorContactCard = ({
  title,
  crewMember,
  hidePhoneNumber = false,
}: SailorContactCardProps) => (
  <Paper
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap-reverse",
      position: "relative",
      overflow: "hidden",
      borderRadius: 2,
      padding: 2,
      gap: 2,
    }}
  >
    <Box
      sx={{
        zIndex: 2,
      }}
    >
      <Typography
        variant="labelLarge"
        sx={{
          mb: 1,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="bodySmall"
        component="div"
        sx={{
          color: "grey.700",
          mb: 0.5,
        }}
      >
        {fullName(crewMember)}
      </Typography>
      <Typography
        variant="bodySmall"
        component="a"
        href={`mailto:${crewMember.email}`}
        noWrap
        sx={{
          color: "grey.700",
        }}
      >
        {crewMember.email}
      </Typography>
      {!hidePhoneNumber && (
        <Typography
          variant="bodySmall"
          component="div"
          noWrap
          sx={{
            color: "grey.700",
            my: 0.5,
          }}
        >
          {formatPhoneNumberIntl(crewMember.phoneNumber)}
        </Typography>
      )}
    </Box>
    {crewMember.profilePic && (
      <Avatar
        src={crewMember.profilePic}
        sx={{
          height: 100,
          maxWidth: "100px",
          flex: "1 1 100px",
          zIndex: 10,
          borderRadius: "50%",
        }}
        alt={`${crewMember.firstName + crewMember.lastName} avatar`}
      />
    )}
  </Paper>
);

export default SailorContactCard;
