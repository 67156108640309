import gql from "graphql-tag";

import offboardingTodo from "apis/fragments/offboardingTodo";
import sailor from "apis/fragments/sailor";
import { Sailor } from "types";

export type SailorData = {
  node: Sailor;
};

const GET_SAILOR = gql`
  query getSailor($id: ID!) {
    node(id: $id) {
      ... on Sailor {
        ...sailor
        canBeReferred
        referralCode
        referrer {
          id
          state
          godfather {
            firstName
            lastName
          }
        }
        accountManager {
          id
          firstName
          lastName
          email
          phoneNumber
          agendaUrl
        }
        businessDeveloper {
          id
          firstName
          lastName
          email
          phoneNumber
          agendaUrl
        }
        offboardingTodo {
          ...offboardingTodo
        }
        vehiclesCount
        completedOnboardingCall
      }
    }
  }
  ${sailor}
  ${offboardingTodo}
`;

export default GET_SAILOR;
