import gql from "graphql-tag";

import { SailorSurvey } from "types";

export type UpdateSailorSurveyData = {
  updateSailorSurvey: {
    sailorSurvey: SailorSurvey;
  };
};

const UPDATE_NPS = gql`
  mutation updateSailorSurvey(
    $id: ID!
    $rating: Int
    $wantToRespond: Boolean
    $comment: String
  ) {
    updateSailorSurvey(
      input: {
        sailorSurveyId: $id
        wantToRespond: $wantToRespond
        rating: $rating
        comment: $comment
      }
    ) {
      sailorSurvey {
        id
        rating
        comment
        wantToRespond
      }
    }
  }
`;

export default UPDATE_NPS;
