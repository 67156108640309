import { useQuery, OperationVariables } from "@apollo/client";
import { Badge } from "@mui/material";
import cookie from "js-cookie";
import { BellOutline } from "mdi-material-ui";

import GET_UNREAD_NOTIFICATIONS_COUNT, {
  UnreadNotificationsCountData,
} from "apis/queries/unreadNotificationsCount";
import CustomIconButton from "components/basics/CustomIconButton";

interface NotificationsIconProps {
  onClick: () => void;
  filters?: OperationVariables;
}

const NotificationsIcon = ({ onClick, filters }: NotificationsIconProps) => {
  const userID = cookie.get("user_id") as string;
  const { data } = useQuery<UnreadNotificationsCountData>(
    GET_UNREAD_NOTIFICATIONS_COUNT,
    {
      variables: { userID, ...filters },
      fetchPolicy: "cache-and-network",
    }
  );

  const unreadNotifsCount = data?.node?.unreadNotificationsCount || 0;

  return (
    <Badge
      badgeContent={unreadNotifsCount}
      color="primary"
      invisible={!unreadNotifsCount}
    >
      <CustomIconButton
        icon={BellOutline}
        onClick={onClick}
        variant="outlined"
        aria-label="Notifications"
        size="large"
        sx={{ height: 42, width: 42 }}
      />
    </Badge>
  );
};

export default NotificationsIcon;
