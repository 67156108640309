import gql from "graphql-tag";

import { Sailor } from "types";

export type SailorCrewMembersData = {
  node: {
    accountManager?: Sailor["accountManager"];
    businessDeveloper?: Sailor["businessDeveloper"];
  };
};

const GET_SAILOR_CREW_MEMBERS = gql`
  query getSailorCrewMembers($id: ID!) {
    node(id: $id) {
      ... on Sailor {
        accountManager {
          id
          firstName
          lastName
          email
          phoneNumber
          agendaUrl
          profilePic
        }
        businessDeveloper {
          id
          firstName
          lastName
          email
          phoneNumber
          agendaUrl
          profilePic
        }
      }
    }
  }
`;

export default GET_SAILOR_CREW_MEMBERS;
