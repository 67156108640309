import { MonthlyStats } from "apis/queries/sailorStatsByMonth";
import theme from "utils/theme";

const {
  palette: { graph },
} = theme;

export type BalanceCategory = {
  label: string;
  dataType: keyof MonthlyStats;
  color: string;
  main: boolean;
};

export const ACTIVITIES_SUMS: BalanceCategory[] = [
  {
    label: "Facturé",
    main: true,
    dataType: "invoicedActivityReportsTotal",
    color: graph.lightGreen,
  },
  {
    label: "Frais de gestion",
    main: false,
    dataType: "managementFeesTotal",
    color: "",
  },
  {
    label: "CVAE",
    main: false,
    dataType: "cvae",
    color: "",
  },
  {
    label: "Réglé",
    main: true,
    dataType: "settledInvoicedActivityReportsTotal",
    color: graph.darkGreen,
  },
];

export const PAYMENTS_SUM: BalanceCategory[] = [
  {
    label: "Versement",
    main: true,
    dataType: "paymentsWithoutActivityReportsAndRefunds",
    color: graph.lightBlue,
  },
  {
    label: "PEE/PERCO",
    main: false,
    dataType: "employeeSavingsPayments",
    color: "",
  },
  {
    label: "Tickets restaurant",
    main: false,
    dataType: "lunchVouchersPayments",
    color: "",
  },
  {
    label: "Comité d'entreprise",
    main: false,
    dataType: "employeeCommitteePayments",
    color: "",
  },
  {
    label: "Autres",
    main: false,
    dataType: "otherPayments",
    color: "",
  },
  {
    label: "Salaire",
    main: true,
    dataType: "globalSalaryCost",
    color: graph.darkBlue,
  },
];
