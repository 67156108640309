import gql from "graphql-tag";

import blob from "apis/fragments/blob";
import mission from "apis/fragments/mission";

export default gql`
  fragment serviceContract on ServiceContract {
    id
    createdAt
    acceptedAt
    state
    endsOn
    rate
    rateType
    mission {
      ...mission
    }
    needsRenewal
    file {
      ...blob
    }
  }
  ${blob}
  ${mission}
`;
