import { Box } from "@mui/material";

import NotificationsIcon from "components/NotificationsIcon";
import PageAppBar from "components/PageAppBar";
import { CategoryIconTypes } from "constants/categoryIconTypes";

const DashboardToolbar = ({
  onDisplayNotifications,
  icon,
  title,
  actions,
}: {
  onDisplayNotifications: () => void;
  icon?: CategoryIconTypes;
  title?: string;
  actions: React.ReactNode;
}) => (
  <PageAppBar
    title={title || "Tableau de bord"}
    category={icon || "dashboard"}
    actions={
      <Box
        sx={{
          display: "flex",
        }}
      >
        {actions}
        <div id="notifications">
          <NotificationsIcon onClick={onDisplayNotifications} />
        </div>
      </Box>
    }
  />
);

export default DashboardToolbar;
