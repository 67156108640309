import { Box, Typography } from "@mui/material";

const ChartHeader = () => (
  <Box
    sx={{
      p: 3,
      borderBottom: "2px solid",
      borderColor: "grey.200",
    }}
  >
    <Typography
      variant="label"
      sx={{
        color: "grey.600",
      }}
    >
      Factures et versements
    </Typography>
    <Typography variant="h4">Histogramme mensuel</Typography>
  </Box>
);

export default ChartHeader;
