import gql from "graphql-tag";

import membershipAgreement from "apis/fragments/membershipAgreement";
import serviceContract from "apis/fragments/serviceContract";
import serviceContractAmendment from "apis/fragments/serviceContractAmendment";
import workContract from "apis/fragments/workContract";
import workContractAmendment from "apis/fragments/workContractAmendment";
import { Contract } from "types";

export type ContractData = Contract & {
  originalContract?: { id: string };
};

export type GetSailorContractsData = {
  node: { contracts: ContractData[] };
};

export default gql`
  query getSailorContracts(
    $sailorID: ID!
    $type: [String!]
    $legalStatus: String
    $state: String
  ) {
    node(id: $sailorID) {
      ... on Sailor {
        id
        contracts(type: $type, legalStatus: $legalStatus, state: $state) {
          ...workContract
          ...serviceContract
          ...membershipAgreement
          ...workContractAmendment
          ... on WorkContractAmendment {
            id
            originalContract {
              id
            }
          }
          ...serviceContractAmendment
          ... on ServiceContractAmendment {
            id
            originalContract {
              id
            }
          }
        }
      }
    }
  }
  ${workContract}
  ${serviceContract}
  ${membershipAgreement}
  ${workContractAmendment}
  ${serviceContractAmendment}
`;
