import { useEffect } from "react";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { Badge, Box, Divider, Typography } from "@mui/material";
import cookie from "js-cookie";

import NotificationCard from "./NotificationCard";
import READ_NOTIFICATIONS from "apis/mutations/readNotifications";
import GET_NOTIFICATIONS, {
  UserNotificationsData,
} from "apis/queries/userNotifications";
import DataPlaceholder from "components/DataPlaceholder";
import LoaderSpinner from "components/LoaderSpinner";
import LoadingOrError from "components/LoadingOrError";
import RecordDrawer, { RecordDrawerProps } from "components/RecordDrawer";
import { Notification } from "types";

const NotificationsBlock = ({
  title,
  items,
  isClientUserable = false,
}: {
  title: string;
  items: Notification[];
  isClientUserable?: boolean;
}) => (
  <>
    <Box
      sx={{
        py: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        align="left"
        sx={{
          color: "primary.main",
          marginRight: "12px",
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{ flexGrow: 1, borderColor: "grey.300", borderWidth: "1px" }}
      />
    </Box>
    {items.map((notification) => (
      <NotificationCard
        key={notification.id}
        notification={notification}
        isClientUserable={isClientUserable}
      />
    ))}
  </>
);

interface NotificationsDrawerProps extends RecordDrawerProps {
  userID: string;
  sailorID?: string;
}

const NotificationsDrawer = ({
  userID,
  sailorID,
  ...props
}: NotificationsDrawerProps) => {
  const currentUserID = userID || (cookie.get("user_id") as string);
  const { error, data, fetchMore, refetch, networkStatus } =
    useQuery<UserNotificationsData>(GET_NOTIFICATIONS, {
      variables: { userID: currentUserID, sailorID, first: 10 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const [readNotifications] = useMutation(READ_NOTIFICATIONS);
  const role = cookie.get("role") as string;
  const isClientUserable = role === "ClientUser" || role === "ClientAdmin";

  const isFetching =
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;
  const isFetchingMore = networkStatus === 3;

  const notificationsNode = data?.node;
  const notifications =
    notificationsNode?.notifications?.edges.map((n) => n.node) || [];
  const newNotifications = notifications.filter((n) => !n.readAt);
  const otherNotifications = notifications.filter((n) => !!n.readAt);
  const hasNextPage = notificationsNode?.notifications?.pageInfo.hasNextPage;

  // Read unread notifications when drawer opens, otherwise refetches when it closes
  useEffect(() => {
    if (notificationsNode && notificationsNode?.unreadNotificationsCount > 0) {
      if (props.open)
        readNotifications({
          variables: { userID: currentUserID, sailorID, all: true },
        });
      else refetch();
    }
  }, [props.open]);

  if (error || !notificationsNode)
    return (
      <RecordDrawer
        headerComponent={<Typography variant="h5">Notifications</Typography>}
        {...props}
      >
        <LoadingOrError
          minHeight={350}
          loading={isFetching}
          errorMessage={error?.message}
        />
      </RecordDrawer>
    );

  const loadMoreNotifications = () =>
    fetchMore({
      variables: {
        first: 5,
        after: notificationsNode?.notifications.pageInfo.endCursor,
      },
    });

  return (
    <RecordDrawer
      headerComponent={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Notifications</Typography>
          {notificationsNode.unreadNotificationsCount > 0 && (
            <Badge
              badgeContent={notificationsNode.unreadNotificationsCount}
              color="secondary"
              sx={{
                ml: 1,
                "& .MuiBadge-badge": {
                  position: "unset",
                  transform: "none",
                  fontWeight: "bold",
                },
              }}
            />
          )}
        </Box>
      }
      {...props}
    >
      {notifications.length === 0 ? (
        <Box
          sx={{
            p: 3,
            px: 2,
          }}
        >
          <DataPlaceholder
            title="Aucune notification"
            description="Vous retrouverez dans cet espace les notifications concernant votre matelot."
          />
        </Box>
      ) : (
        <>
          {newNotifications.length > 0 && (
            <NotificationsBlock title="Nouveau" items={newNotifications} />
          )}
          {otherNotifications.length > 0 && (
            <NotificationsBlock
              title="Plus tôt"
              items={otherNotifications}
              isClientUserable={isClientUserable}
            />
          )}
        </>
      )}
      {!isFetching && hasNextPage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isFetchingMore ? (
            <LoaderSpinner size={20} delay={200} />
          ) : (
            <ExpandMoreOutlined
              style={{ cursor: "pointer" }}
              color="primary"
              onClick={loadMoreNotifications}
            />
          )}
        </Box>
      )}
    </RecordDrawer>
  );
};

export default NotificationsDrawer;
