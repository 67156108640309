import { useQuery } from "@apollo/client";
import { Paper, Box, Typography } from "@mui/material";
import { subMonths, startOfMonth, isSameMonth, format } from "date-fns";
import { fr } from "date-fns/locale";
import cookie from "js-cookie";

import MissionDeclaration, { MissionToDeclare } from "./MissionDeclaration";
import MISSIONS_WITHOUT_DECLARATION, {
  MissionsWithoutActivityDeclarationData,
} from "apis/queries/sailor/missionsWithoutActivityDeclaration";
import StyledAlert from "components/basics/StyledAlert";
import LoaderSpinner from "components/LoaderSpinner";

type MissionReports =
  | MissionsWithoutActivityDeclarationData["node"]["missions"]["nodes"][0]["activityReports"]
  | MissionsWithoutActivityDeclarationData["node"]["missions"]["nodes"][0]["expenseReports"];

const DeclareMissionsActivityPaper = () => {
  const sailorID = cookie.get("user_id") as string;
  const currentDay = new Date().getDate();
  const declaringMonth =
    currentDay <= 11
      ? subMonths(startOfMonth(new Date()), 1)
      : startOfMonth(new Date());

  const { data, loading } = useQuery<MissionsWithoutActivityDeclarationData>(
    MISSIONS_WITHOUT_DECLARATION,
    {
      variables: { sailorID, activityMonth: declaringMonth },
      fetchPolicy: "cache-and-network",
      skip: currentDay > 10 && currentDay < 21,
    }
  );

  const prevMonthMissions = data?.node.missions.nodes;

  // If nothing to declare, we do not render the paper
  if (!loading && (!prevMonthMissions || prevMonthMissions?.length === 0))
    return null;

  const hasSubmittedReport = (
    reports: MissionReports,
    noReportMonths: string[]
  ) =>
    reports.find(
      (r) =>
        r.state !== "draft" &&
        r.state !== "cancelled" &&
        isSameMonth(new Date(r.month), declaringMonth)
    ) ||
    noReportMonths.find((month) =>
      isSameMonth(new Date(month), declaringMonth)
    );

  const missionsToDeclare =
    prevMonthMissions?.reduce<MissionToDeclare[]>((agg, mission) => {
      const submittedActivityReport = hasSubmittedReport(
        mission.activityReports,
        mission.monthsWithoutActivity
      );
      const submittedExpenseReport = hasSubmittedReport(
        mission.expenseReports,
        mission.monthsWithoutExpense
      );

      if (!submittedActivityReport || !submittedExpenseReport)
        return [
          ...agg,
          {
            missionID: mission.id,
            clientName: mission.client.name,
            month: declaringMonth,
            activityReport: !submittedActivityReport,
            expenseReport: !submittedExpenseReport,
          },
        ];

      return agg;
    }, []) || [];

  const CompletedAlert = () => (
    <StyledAlert
      title={`Merci, vos démarches ont été complétées pour ${format(
        declaringMonth,
        "LLLL Y",
        { locale: fr }
      )}.`}
    />
  );

  return (
    <Paper sx={{ borderRadius: 2, mb: 3 }}>
      <Box
        sx={{
          p: ({ spacing }) => spacing(3, 2),
          borderBottom: 1,
          borderColor: "grey.300",
        }}
      >
        <Typography variant="labelLarge">Accélérez votre paiement</Typography>
        <Typography
          variant="bodySmall"
          sx={{
            color: "grey.600",
          }}
        >
          Faites vos déclarations pour accélérer les démarches de votre
          versement de salaire.
        </Typography>
      </Box>
      <Box sx={{ p: ({ spacing }) => spacing(3, 2) }}>
        {loading && !data && <LoaderSpinner />}
        {missionsToDeclare[0] ? (
          <MissionDeclaration mission={missionsToDeclare[0]} />
        ) : (
          !loading && <CompletedAlert />
        )}
      </Box>
    </Paper>
  );
};

export default DeclareMissionsActivityPaper;
