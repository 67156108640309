import gql from "graphql-tag";

import serviceContract from "./serviceContract";
import blob from "apis/fragments/blob";

export default gql`
  fragment serviceContractAmendment on ServiceContractAmendment {
    id
    createdAt
    acceptedAt
    state
    startsOn
    endsOn
    rate
    rateType
    needsRenewal
    mission {
      ...mission
    }
    file {
      ...blob
    }
    originalContract {
      ...serviceContract
    }
  }
  ${serviceContract}
  ${blob}
`;
