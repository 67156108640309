import gql from "graphql-tag";

import { SailorSurvey } from "types";

export type LastSailorSurveyData = {
  lastSailorSurvey: SailorSurvey;
};

export const GET_LAST_SAILOR_SURVEY = gql`
  query lastSailorSurvey {
    lastSailorSurvey {
      id
      rating
      comment
      wantToRespond
      updatedAt
      sailor {
        id
      }
    }
  }
`;
