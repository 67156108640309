import gql from "graphql-tag";

import { PendingInvoice } from "types";

export type PendingInvoicesData = {
  invoices: {
    totalCount: number;
    edges: Array<{ node: PendingInvoice }>;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
};

// we ignore expenses invoices because they should not be included in the dashboard
export default gql`
  query getPendingInvoices($first: Int, $after: String) {
    invoices(
      first: $first
      after: $after
      state: "pending_settlement"
      invoiceType: ["activity_report", "activity_report_expense"]
      dueDateSort: "ASC"
    ) {
      totalCount
      edges {
        node {
          id
          remainingAmountDue
          forecastSettledAmount
          issuedOn
          dueDate
          month
          state
          client {
            id
            name
          }
          mission {
            id
            paymentPeriod
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;
