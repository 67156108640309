import { ChevronRightOutlined, ChevronLeftOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import CustomIconButton from "components/basics/CustomIconButton";
import { formatDate } from "utils/dates";

type ChartPaginationProps = {
  months: string[];
  handlePageChange: (direction: string) => void;
};

const ChartPagination = ({
  months,
  handlePageChange,
}: ChartPaginationProps) => {
  const lastMonth = months[0];
  const firstMonth = months[5];

  const isCurrentMonth =
    firstMonth.toLowerCase() ===
    formatDate(new Date(), {
      month: "long",
      year: "2-digit",
    });

  return (
    <Box
      sx={{
        textAlign: "right",
        pt: { xs: 2, lg: 4.5 },
      }}
    >
      <Typography variant="bodySmall">
        {lastMonth} à {firstMonth}
      </Typography>
      <CustomIconButton
        icon={ChevronLeftOutlined}
        iconSize="small"
        onClick={() => handlePageChange("prev")}
        sx={{
          color: "grey.900",
          "&:hover": {
            background: "none",
          },
        }}
      />
      <CustomIconButton
        icon={ChevronRightOutlined}
        iconSize="small"
        onClick={() => handlePageChange("next")}
        disabled={isCurrentMonth}
        sx={{
          color: "grey.900",
          "&:hover": {
            background: "none",
          },
        }}
      />
    </Box>
  );
};

export default ChartPagination;
