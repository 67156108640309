import gql from "graphql-tag";

import { Settlement, Payment } from "types";

export type SettlementsPaymentsData = {
  settlementsPaymentsCount: number;
  settlementsPayments: {
    edges: Array<{ node: Settlement | Payment }>;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
};

export default gql`
  query getSettlementsPayments(
    $first: Int
    $after: String
    $sailorID: ID
    $month: Int
    $year: Int
    $paymentTypes: [String!]
  ) {
    settlementsPaymentsCount(
      sailorId: $sailorID
      month: $month
      year: $year
      paymentTypes: $paymentTypes
    )
    settlementsPayments(
      sailorId: $sailorID
      month: $month
      year: $year
      first: $first
      after: $after
      paymentTypes: $paymentTypes
    ) {
      edges {
        node {
          ... on Settlement {
            id
            amount
            date
            invoice {
              id
              issuedOn
              month
              amountInclTax
              amountExclTax
              remainingAmountDue
              client {
                name
              }
            }
          }
          ... on Payment {
            id
            reference
            month
            paymentType
            description
            amount
            registeredAt
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;
