import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import CustomIconButton, {
  CustomIconButtonProps,
} from "components/basics/CustomIconButton";
import { formatDate } from "utils/dates";

const StyledIconButton = ({ ...props }: CustomIconButtonProps) => (
  <CustomIconButton
    iconSize="small"
    sx={{
      "&:hover": {
        background: "transparent",
      },
    }}
    {...props}
  />
);

interface MonthSelectorProps {
  activeMonth: number;
  months: string[];
  handlePageChange: (direction: string) => void;
  setActiveMonth: (month: number) => void;
}

const MonthSelector = ({
  activeMonth,
  months,
  handlePageChange,
  setActiveMonth,
}: MonthSelectorProps) => {
  const isCurrentMonth =
    months[activeMonth].toLowerCase() ===
    formatDate(new Date(), {
      month: "long",
      year: "2-digit",
    });

  const handleMonthChange = (direction: string) => {
    const targetMonth = activeMonth + (direction === "next" ? 1 : -1);
    if (targetMonth >= 0 && targetMonth < 6) {
      setActiveMonth(targetMonth);
    } else {
      handlePageChange(direction);
      setActiveMonth(direction === "next" ? 0 : 5);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <StyledIconButton
        icon={ChevronLeftOutlined}
        onClick={() => handleMonthChange("prev")}
      />
      <Typography variant="labelLarge">{months[activeMonth]}</Typography>
      <StyledIconButton
        icon={ChevronRightOutlined}
        onClick={() => handleMonthChange("next")}
        disabled={isCurrentMonth}
      />
    </Box>
  );
};

export default MonthSelector;
