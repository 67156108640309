import React from "react";
import dynamic from "next/dynamic";
import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid2";
import { isWithinInterval, subDays } from "date-fns";
import cookie from "js-cookie";

import GET_SAILOR, { SailorData } from "apis/queries/getSailor";
import GET_SAILOR_TODO, {
  SailorOnboardingTodoData,
} from "apis/queries/getSailorOnboardingTodo";
import {
  GET_LAST_SAILOR_SURVEY,
  LastSailorSurveyData,
} from "apis/queries/lastSailorSurvey";
import DashboardLayout from "components/dashboard/DashboardLayout";
import DeclareMissionsActivityPaper from "components/dashboard/DeclareMissionsActivityPaper";
import SailorBalanceAndTransactions from "components/dashboard/SailorBalanceAndTransactions";
import SailorStatsWithCharts from "components/dashboard/SailorStatsWithCharts/";
import NPS from "components/NpsDialog";
import OffboardedAlert from "components/OffboardedAlert";
import OnboardingCallAlert from "components/OnboardingCallAlert";
import EventDialog from "components/sailor-app/events/Dialog";
import SailorOnboardingSteps from "components/SailorOnboardingSteps";
import SailorShortcuts from "components/SailorShortcuts";
import ServicesAndContactPaper from "components/ServicesAndContactPaper";
import withNavigationLayout from "components/withNavigationLayout";
import EVENTS from "constants/events";
import isSailorSurveyable from "utils/nps";
import { isCrewMember } from "utils/users";

const AppTour = dynamic(() => import("components/AppTour"), { ssr: false });

const Dashboard = () => {
  const userID = cookie.get("user_id") as string;
  const role = cookie.get("role") as string;

  const { data } = useQuery<SailorData>(GET_SAILOR, {
    variables: {
      id: userID,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  });

  const sailor = data?.node;

  const { data: todoData } = useQuery<SailorOnboardingTodoData>(
    GET_SAILOR_TODO,
    {
      variables: {
        id: userID,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
      },
      skip: !sailor || !["invited", "onboarding"].includes(sailor.state),
    }
  );

  const todo = todoData?.node?.onboardingTodo;

  const { data: sailorNPS } = useQuery<LastSailorSurveyData>(
    GET_LAST_SAILOR_SURVEY,
    {
      variables: {
        fetchPolicy: "cache-and-network",
      },
      skip: !sailor || ["invited", "onboarding"].includes(sailor.state),
    }
  );

  const lastSailorSurvey = sailorNPS?.lastSailorSurvey;
  const isSailorOff =
    sailor?.state === "offboarding" || sailor?.state === "offboarded";
  const canNotEdit = isSailorOff && !isCrewMember(role);

  const displayOnboardingCallAlert =
    todo?.workContractEmail.checked && !todo?.workContractCall.checked;

  const agendaUrl = sailor?.accountManager?.agendaUrl;

  const events = EVENTS.filter((e) =>
    isWithinInterval(new Date(), {
      start: subDays(new Date(e.date), 10),
      end: new Date(e.date),
    })
  );
  const event = events[0];
  const eventDialogSeen = localStorage.getItem("eventDialogSeen") as
    | string
    | null;
  const hideEventModal =
    eventDialogSeen &&
    isWithinInterval(new Date(eventDialogSeen), {
      start: subDays(new Date(), 10),
      end: new Date(),
    });

  return (
    <DashboardLayout
      title="Dashboard"
      toolbarIcons={<SailorShortcuts sailorState={sailor?.state} />}
    >
      {sailor && <AppTour open={!sailor.welcomed} sailorID={sailor.id} />}
      <Grid
        container
        spacing={[2, 3]}
        sx={{
          justifyContent: "center",
          padding: [2, 2, 3],
        }}
      >
        <OffboardedAlert
          display={canNotEdit}
          sx={{ pl: [2, 2, 3], pt: [2, 2, 3], width: "100%" }}
        />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          size={{
            xs: 12,
            lg: 8,
          }}
        >
          {agendaUrl && displayOnboardingCallAlert && (
            <OnboardingCallAlert agendaUrl={agendaUrl} />
          )}
          {sailor?.state === "onboarding" && (
            <SailorOnboardingSteps sailor={sailor} />
          )}
          <SailorBalanceAndTransactions sailorID={userID} />
          <SailorStatsWithCharts sailorID={userID} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 4,
          }}
        >
          <DeclareMissionsActivityPaper />
          <ServicesAndContactPaper
            sailorID={userID}
            completedOnboardingCall={sailor?.completedOnboardingCall}
          />
        </Grid>
      </Grid>
      {lastSailorSurvey &&
        isSailorSurveyable(lastSailorSurvey) &&
        (hideEventModal || !event) && <NPS sailorSurvey={lastSailorSurvey} />}
      {event && !hideEventModal && <EventDialog event={event} />}
    </DashboardLayout>
  );
};

export default withNavigationLayout(Dashboard);
