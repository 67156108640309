import { ForwardToInboxOutlined } from "@mui/icons-material";
import { SvgIconTypeMap, ChipProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  AccountClock,
  LocationExit,
  Bed,
  CheckCircleOutline,
  TimerSandEmpty,
} from "mdi-material-ui";

import { Sailor } from "types";

export const STATE_LABELS: Record<string, string> = {
  invited: "Invité",
  onboarding: "Embarquement",
  onboarded: "Embarqué",
  offboarding: "Débarquement",
  offboarded: "Débarqué",
};

export const STATE_CHIP_COLORS: Record<
  string,
  {
    color: ChipProps["color"];
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  }
> = {
  invited: {
    color: "primary",
    icon: ForwardToInboxOutlined,
  },
  onboarding: {
    color: "info",
    icon: TimerSandEmpty,
  },
  onboarded: {
    color: "success",
    icon: CheckCircleOutline,
  },
  offboarding: {
    color: "warning",
    icon: LocationExit,
  },
  offboarded: {
    color: "error",
    icon: Bed,
  },
};

export const MISSION_STATUS_CHIP: Record<
  Sailor["missionStatus"],
  {
    label: string;
    color: ChipProps["color"];
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  }
> = {
  incoming: {
    label: "À venir",
    color: "warning",
    icon: AccountClock,
  },
  ongoing: {
    label: "En mission",
    color: "info",
    icon: TimerSandEmpty,
  },
  ended: {
    label: "Terminée",
    color: "success",
    icon: CheckCircleOutline,
  },
};

export const OFFBOARDING_REASONS: Record<string, string> = {
  fixed_trial_period_ending_embarq: "Rupture PE initiative Embarq - CDD",
  fixed_trial_period_ending_sailor: "Rupture PE initiative consultant - CDD",
  fixed_ending_mutual_consent: "Rupture d'un commun accord - CDD",
  fixed_contract_ending: "Fin de contrat - CDD",
  permanent_trial_period_ending_embarq: "Rupture PE initiative Embarq - CDI",
  permanent_trial_period_ending_sailor:
    "Rupture PE initiative consultant - CDI",
  permanent_sailor_resignation: "Démission - CDI",
  permanent_ending_mutual_consent: "Rupture conventionnelle - CDI",
  dismissal: "Licenciement",
  retirement: "Départ à la retraite à l'initiative du salarié",
  deceased: "Décès",
};

export const REQUIRED_SAILOR_GENERAL_INFO = [
  "gender",
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
];

export const REQUIRED_SAILOR_PERSONAL_INFO = [
  "occupation",
  "birthDate",
  "birthCity",
  "birthCountry",
  "nationality",
  "socialSecurityNumber",
  "bic",
  "iban",
];

export const REQUIRED_SAILOR_ADDRESS_INFO = ["address", "postalCode", "city"];

export const REQUIRED_SAILOR_INFOS = REQUIRED_SAILOR_GENERAL_INFO.concat(
  REQUIRED_SAILOR_PERSONAL_INFO,
  REQUIRED_SAILOR_ADDRESS_INFO
);

export const REQUIRED_SAILOR_DOCS = [
  "id_card",
  "social_security",
  "address_proof",
  "resume",
];
