import gql from "graphql-tag";

export type UnreadNotificationsCountData = {
  node: {
    unreadNotificationsCount: number;
  };
};

export default gql`
  query getUnreadNotificationsCount($userID: ID!, $sailorID: ID) {
    node(id: $userID) {
      ... on Sailor {
        id
        unreadNotificationsCount
      }
      ... on AccountManager {
        id
        unreadNotificationsCount(sailorId: $sailorID)
      }
      ... on BusinessDeveloper {
        id
        unreadNotificationsCount(sailorId: $sailorID)
      }
      ... on ClientAdmin {
        id
        unreadNotificationsCount(sailorId: $sailorID)
      }
    }
  }
`;
