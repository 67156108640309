import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, TablePagination } from "@mui/material";

import GET_PENDING_INVOICES, {
  PendingInvoicesData,
} from "apis/queries/invoices/pending";
import GET_SAILOR_TRANSACTIONS, {
  SettlementsPaymentsData,
} from "apis/queries/settlementsPayments";
import TransactionItem from "components/dashboard/TransactionItem";
import DataPlaceholder from "components/DataPlaceholder";
import LoadingOrError from "components/LoadingOrError";

interface TransactionsListProps {
  forecast?: boolean;
  sailorID: string;
  children: React.ReactNode;
}

const TransactionsList = ({
  forecast,
  sailorID,
  children,
}: TransactionsListProps) => {
  const [queryVariables, setQueryVariables] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const { page, rowsPerPage } = queryVariables;

  const { loading, error, data, fetchMore } = useQuery<
    PendingInvoicesData | SettlementsPaymentsData
  >(forecast ? GET_PENDING_INVOICES : GET_SAILOR_TRANSACTIONS, {
    variables: {
      sailorID,
      first: queryVariables.rowsPerPage,
      paymentTypes: forecast
        ? ""
        : [
            "activity_report",
            "lunch_voucher",
            "other",
            "refund",
            "employee_savings",
            "christmas_voucher",
            "medical",
            "employee_committee",
          ],
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  if (loading || error || !data)
    return (
      <Box
        sx={{
          p: 3,
          borderRadius: 2,
        }}
      >
        <LoadingOrError minHeight={350} loading={loading} />
      </Box>
    );

  const transactionsCount = forecast
    ? (data as PendingInvoicesData).invoices.totalCount
    : (data as SettlementsPaymentsData).settlementsPaymentsCount;

  const rawData = forecast
    ? (data as PendingInvoicesData).invoices
    : (data as SettlementsPaymentsData).settlementsPayments;

  const transactions = rawData.edges.map((t) => t.node);
  const { endCursor } = rawData.pageInfo;

  const transactionsDisplayed = transactions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement | MouseEvent> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      fetchMore({
        variables: { after: endCursor },
      });
    }
    setQueryVariables({ ...queryVariables, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQueryVariables({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  return (
    <Box
      sx={{
        p: [2, 3],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
      {transactionsCount === 0 ? (
        <DataPlaceholder
          title={
            forecast
              ? "Aucune transaction à venir"
              : "Aucune transaction à afficher"
          }
        />
      ) : (
        <Box
          sx={{
            "> div": {
              mt: 3,
            },
          }}
        >
          {transactionsDisplayed.map((t) => (
            <TransactionItem key={t.id} transaction={t} future={forecast} />
          ))}
        </Box>
      )}
      {transactionsCount > 5 && (
        <TablePagination
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count}`
          }
          count={transactionsCount}
          rowsPerPageOptions={[
            5,
            10,
            20,
            50,
            { value: transactionsCount, label: "Tout" },
          ]}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          sx={{
            mt: 3,
            ".MuiToolbar-root": {
              p: 0,
            },
            ".MuiTablePagination-displayedRows": {
              textAlign: "right",
              flexGrow: 1,
            },
            ".MuiTablePagination-spacer": {
              flex: "none",
            },
          }}
        />
      )}
    </Box>
  );
};

export default TransactionsList;
