import gql from "graphql-tag";

import { Sailor, Mission, Client, ActivityReport, ExpenseReport } from "types";

export type MissionsWithoutActivityDeclarationData = {
  node: Pick<Sailor, "id"> & {
    missions: {
      nodes: Array<
        Pick<
          Mission,
          "id" | "name" | "monthsWithoutActivity" | "monthsWithoutExpense"
        > & {
          client: Pick<Client, "id" | "name">;
          activityReports: Array<
            Pick<ActivityReport, "id" | "state" | "month">
          >;
          expenseReports: Array<Pick<ExpenseReport, "id" | "state" | "month">>;
        }
      >;
    };
  };
};

export default gql`
  query missionsWithoutActivityDeclaration(
    $sailorID: ID!
    $activityMonth: ISO8601Date
  ) {
    node(id: $sailorID) {
      ... on Sailor {
        id
        missions(activityMonth: $activityMonth) {
          nodes {
            id
            name
            monthsWithoutActivity
            monthsWithoutExpense
            client {
              id
              name
            }
            activityReports {
              id
              state
              month
            }
            expenseReports {
              id
              state
              month
            }
          }
        }
      }
    }
  }
`;
