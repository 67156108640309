import { ChevronRightOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

interface ServiceCardProps {
  title: string;
  description: string;
  href: string;
  id?: string;
  disabled?: boolean;
  openNewTab?: boolean;
}

function ServiceCard({
  title,
  description,
  href,
  id,
  disabled,
  openNewTab,
}: ServiceCardProps) {
  return (
    <Box
      id={id}
      sx={{
        p: 2,
        color: "text.primary",
        borderRadius: 1,
      }}
    >
      <Typography variant="labelLarge">{title}</Typography>
      <Typography
        variant="bodySmall"
        sx={{
          display: "block",
          color: "grey.600",
        }}
      >
        {description}
      </Typography>
      <Button
        disabled={disabled}
        variant="outlined"
        target={openNewTab ? "_blank" : undefined}
        rel="noopener noreferrer"
        href={href}
        color="inherit"
        size="small"
        endIcon={<ChevronRightOutlined sx={{ color: "primary.main" }} />}
        sx={{
          borderColor: "primary.background",
          mt: 2,
          borderRadius: 2,
          "&.Mui-disabled": {
            color: "grey.600",
          },
        }}
      >
        {disabled ? "A venir" : "Découvrir"}
      </Button>
    </Box>
  );
}

export default ServiceCard;
