import gql from "graphql-tag";

import onboardingTodo from "apis/fragments/onboardingTodo";
import { Sailor } from "types";

export type SailorOnboardingTodoData = {
  node: Pick<Sailor, "id" | "onboardingTodo">;
};

export default gql`
  query getSailorOnboardingTodo($id: ID!) {
    node(id: $id) {
      ... on Sailor {
        id
        onboardingTodo {
          ...onboardingTodo
        }
      }
    }
  }
  ${onboardingTodo}
`;
