export type Event = {
  title: string;
  subTitle: string;
  date: string;
  location: string;
  tickets: number;
  description: string | (string | JSX.Element)[];
  url: string;
};

const EVENTS: Array<Event> = [
  {
    title: "Atelier Exclusif sur LinkedIn",
    subTitle:
      "Performez sur LinkedIn : Devenez un as de ce réseau pro incontournable",
    date: "2024-03-01",
    location: "les bureaux parisiens d'Embarq, dans le 10ème",
    tickets: 10,
    description: [
      "Maîtrisez les secrets de LinkedIn pour booster votre profil professionnel. Cet atelier vous apprendra à optimiser votre présence sur ce réseau incontournable, à élargir votre réseau et à maximiser vos opportunités professionnelles.",
      <br key={1} />,
      <br key={2} />,
      "✅ Ne ratez pas cette occasion unique de développer vos compétences LinkedIn !",
    ],
    url: "https://www.eventbrite.fr/e/billets-performez-sur-linkedin-devenez-un-as-de-ce-reseau-pro-incontournable-795968141137",
  },
];

export default EVENTS;
