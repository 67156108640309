import React from "react";
import {
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  InputBase,
} from "@mui/material";

interface NpsFollowUpProps {
  sentiment: "positive" | "negative";
  handleAction: () => void;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
}

const WORDING = {
  positive: {
    title:
      "Laissez-nous un avis sur Google afin de nous permettre de gagner en visibilité !",
    button: "Je laisse un avis",
  },
  negative: {
    title:
      "Nous sommes navrés que votre expérience n'ait pas été à la hauteur, que pouvons-nous améliorer ?",
    button: "Envoyer le commentaire",
  },
};

const NpsFollowUp = ({
  sentiment,
  handleAction,
  comment,
  setComment,
}: NpsFollowUpProps) => (
  <>
    <DialogTitle
      sx={{ width: "70%", mx: "auto" }}
      variant="subtitle2"
      textAlign="center"
    >
      Merci pour votre retour !
    </DialogTitle>
    <DialogContent>
      <Typography>{WORDING[sentiment].title}</Typography>
      {sentiment === "negative" && (
        <InputBase
          multiline
          fullWidth
          placeholder="Tapez votre message..."
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          sx={{
            "&.MuiInputBase-root": {
              marginTop: 2,
              bgcolor: "grey.50",
              borderRadius: 2,
              padding: 2,
              "& .MuiInputBase-input": { minHeight: 108 },
            },
          }}
        />
      )}
    </DialogContent>
    <DialogActions sx={{ mx: "auto" }}>
      <Button autoFocus onClick={handleAction} variant="contained">
        {WORDING[sentiment].button}
      </Button>
    </DialogActions>
  </>
);

export default React.memo(NpsFollowUp);
