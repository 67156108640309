import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { sub, add } from "date-fns";

import ChartHeader from "./ChartHeader";
import SailorHistoryChart from "./SailorHistoryChart";
import GET_SAILOR_STATS_BY_MONTH, {
  StatsDataByMonth,
} from "apis/queries/sailorStatsByMonth";
import MonthSelector from "components/dashboard/MonthSelector";
import SailorMonthlyBalanceDetails from "components/dashboard/SailorMonthlyBalanceDetails";
import ChartPagination from "components/dashboard/SailorStatsWithCharts/ChartPagination";
import LoadingOrError from "components/LoadingOrError";
import { formatDate } from "utils/dates";
import theme from "utils/theme";

interface SailorStatsProps {
  sailorID: string;
}

const getLast6MonthsFromDate = (date: Date) => {
  const months = [];
  for (let i = 6; i > 0; i -= 1) {
    const monthToAdd = formatDate(sub(date, { months: i - 1 }), {
      month: "long",
      year: "2-digit",
    });
    months.push(monthToAdd[0].toUpperCase() + monthToAdd.substring(1));
  }
  return months;
};

const SailorStatsWithCharts = ({ sailorID }: SailorStatsProps) => {
  const [activeMonth, setActiveMonth] = useState(5);
  const [baseDate, setBaseDate] = useState(new Date(Date.now()));

  const hideChart = useMediaQuery(theme.breakpoints.down(520));

  const { data, loading, error } = useQuery<StatsDataByMonth>(
    GET_SAILOR_STATS_BY_MONTH,
    {
      variables: {
        id: sailorID,
        month: baseDate,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  if (error || !data)
    return (
      <Paper sx={{ borderRadius: 2 }}>
        <ChartHeader />
        <Box
          sx={{
            p: 3,
          }}
        >
          <LoadingOrError loading={loading} minHeight={300} />
        </Box>
      </Paper>
    );

  const statsByMonth = data?.node?.statsByMonth;

  const labels = getLast6MonthsFromDate(baseDate);

  const handlePageChange = (direction: string) => {
    if (direction === "next") setBaseDate(add(baseDate, { months: 6 }));
    else setBaseDate(sub(baseDate, { months: 6 }));
    setActiveMonth(5);
  };

  return (
    <Paper sx={{ borderRadius: 2 }}>
      <ChartHeader />
      <Box
        sx={{
          p: 3,
        }}
      >
        {hideChart ? (
          <MonthSelector
            months={labels}
            handlePageChange={handlePageChange}
            activeMonth={activeMonth}
            setActiveMonth={setActiveMonth}
          />
        ) : (
          <Typography variant="labelLarge">{labels[activeMonth]}</Typography>
        )}
        <SailorMonthlyBalanceDetails
          statsByMonth={statsByMonth}
          activeMonth={activeMonth}
        />
        {!hideChart && (
          <>
            <SailorHistoryChart
              rawData={statsByMonth}
              labels={labels}
              activeMonth={activeMonth}
              setActiveMonth={setActiveMonth}
            />
            <ChartPagination
              months={labels}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default SailorStatsWithCharts;
