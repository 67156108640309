import gql from "graphql-tag";

import { Sailor } from "types";

export type SailorBalancesData = {
  node: {
    id: Sailor["id"];
    stats: Pick<
      Sailor["stats"],
      "availableBalance" | "forecastSettledAmount" | "provisionBalance"
    >;
  };
};

export default gql`
  query getSailorBalances($id: ID!) {
    node(id: $id) {
      ... on Sailor {
        id
        stats {
          availableBalance
          forecastSettledAmount
          provisionBalance
        }
      }
    }
  }
`;
