import gql from "graphql-tag";

import todoItemFields from "./todoItemFields";

export default gql`
  fragment offboardingTodo on OffboardingTodo {
    id
    terminationNotificationSent {
      ...todoItemFields
    }
    terminationHomologation {
      ...todoItemFields
    }
    healthInsurance {
      ...todoItemFields
    }
    silae {
      ...todoItemFields
    }
    leeto {
      ...todoItemFields
    }
    epsor {
      ...todoItemFields
    }
    occupationalHealth {
      ...todoItemFields
    }
    reason
    effectiveDate
  }
  ${todoItemFields}
`;
