import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment workContractAmendment on WorkContractAmendment {
    id
    createdAt
    acceptedAt
    state
    legalStatus
    startsOn
    endsOn
    file {
      ...blob
    }
  }
  ${blob}
`;
