import camelcaseKeys from "camelcase-keys";
import { FormikHelpers } from "formik";

export type SubmitError = ApolloRequestError | ApiError | Error;

interface ApolloRequestError extends Error {
  networkError?: any;
  graphQLErrors: GraphqlErrors["errors"];
}

type FormValues = { [key: string]: any };

const formikRecordErrors = (
  recordErrors: RecordError[],
  {
    setTouched,
    setStatus,
  }: Pick<FormikHelpers<FormValues>, "setTouched" | "setStatus">
) => {
  const apiErrors = camelcaseKeys(recordErrors);
  const fieldsToReset = Object.fromEntries(
    Object.entries(apiErrors).map(([k]) => [k, false])
  );
  setTouched(fieldsToReset, false); // Reset fields touched to `false` to keep the apiError displayed
  setStatus({ apiErrors });
};

const isError = (error: unknown): error is Error =>
  typeof error === "object" && (error as Error).message !== undefined;

export const isGraphQLError = (error: unknown): error is ApolloRequestError =>
  typeof error === "object" &&
  (error as ApolloRequestError).graphQLErrors !== undefined;

export const isApiError = (error: unknown): error is ApiError =>
  typeof error === "object" && (error as ApiError).error !== undefined;

const handleFormikSubmitError = (
  err: unknown,
  {
    setTouched,
    setStatus,
    setSubmitting,
  }: Pick<
    FormikHelpers<FormValues>,
    "setTouched" | "setStatus" | "setSubmitting"
  >
) => {
  if (isGraphQLError(err) && err.graphQLErrors?.[0]) {
    // err is an ApolloRequestError
    const graphqlError = err.graphQLErrors[0];

    if (graphqlError.extensions.code === "invalid_record") {
      formikRecordErrors(
        (<GraphqlError<InvalidRecordError>>graphqlError).extensions
          .record_errors,
        { setTouched, setStatus }
      );
    } else setStatus({ error: graphqlError.message });
  } else if (isApiError(err)) {
    // err is an ApiError
    if (err.error.record_errors) {
      formikRecordErrors(err.error.record_errors, { setTouched, setStatus });
    } else setStatus({ error: err.error.message });
  } else if (isError(err)) {
    // err is an Error
    setStatus({ error: err.message });
  } else setStatus({ error: "Une erreur est survenue" });
  setSubmitting(false);
};

const errorMessage = (
  err: unknown,
  defaultMessage: string = "Une erreur est survenue"
) => {
  if (isGraphQLError(err) && err.graphQLErrors?.[0]) {
    const graphQLError = err.graphQLErrors[0];
    if (
      graphQLError.extensions.code === "invalid_record" &&
      (<GraphqlError<InvalidRecordError>>graphQLError).extensions.record_errors
    ) {
      const recordErrors = (<GraphqlError<InvalidRecordError>>graphQLError)
        .extensions.record_errors;
      const recordErrorsMsg = Object.entries(recordErrors).map(
        ([k, v]) => `"${k}" ${v}`
      );
      return `${graphQLError.message} : \n${recordErrorsMsg.join("\n")}`;
    }

    return graphQLError.message;
  }
  if (isGraphQLError(err) && err.networkError) {
    return err.networkError.result.error.message;
  }
  if (isApiError(err)) return err.error.message;
  if (isError(err)) return err.message;
  return defaultMessage;
};

export { handleFormikSubmitError, errorMessage };
